<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-icon class="white--text" style="font-size: 45px"
                >mdi-account-supervisor-circle</v-icon
            >
            <pre><v-toolbar-title><h2>Gestión de Proyectos</h2></v-toolbar-title></pre>
        </v-toolbar>
        <loader v-if="isloading"></loader>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="'/'"
                    :loading="isloading"
                    :disabled="isloading"
                    color="blue"
                    class="mt-2 mx-3"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-arrow-left-bold-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Volver</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="{ path: `/guardar-cliente` }"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-account-plus-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Nuevo Cliente</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    @click="exportExcel"
                    :loading="isloading"
                    :disabled="isloading"
                    color="success"
                    class="mt-2 mx-3"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-file-excel</v-icon>
                </v-btn>
            </template>
            <span>Exportar excel</span>
        </v-tooltip>
        <v-row class="mx-2 my-2">
            <v-row>
                <v-col cols="10" justify="center">
                    <FilterComponentVue
                        :headers="filter_headers"
                        v-model="filtros_prueba"
                    ></FilterComponentVue>
                </v-col>
            </v-row>

            <v-row align-md="end" justify="end" class="mr-4 mb-2">
                <v-btn-toggle v-model="toggle_exclusive" rounded>
                    <v-btn>
                        <v-icon>mdi-filter-plus-outline</v-icon
                        ><strong>
                            <pre style="color: green"> Proy. Activos</pre>
                        </strong>
                    </v-btn>
                    <v-btn>
                        <v-icon>mdi-filter-minus-outline</v-icon
                        ><strong>
                            <pre style="color: red"> Proy. Inactivos</pre>
                        </strong>
                    </v-btn>
                    <v-btn>
                        <v-icon>mdi-filter</v-icon
                        ><strong>
                            <pre style="color: blue"> Todos</pre>
                        </strong>
                    </v-btn>
                </v-btn-toggle>
            </v-row>
        </v-row>

        <v-data-table
            @click:row="
                (item) => {
                    $router.push(`/guardar-proyecto-gestion?id=${item.id}`);
                }
            "
            dense
            :server-items-length="totalItems"
            :loading="loading"
            :headers="headers"
            :items="proyectos"
            :search="search"
            item-key="id"
            class="elevation-1 pointer"
            :options.sync="options"
            @update:options="onOptionsUpdate"
        >
            <template v-slot:item.tipo="{ item }">
                <span v-if="item.semanal == 0">Único</span>
                <span v-if="item.semanal == 1">Semanal</span>
                <span v-if="item.semanal == 2">Mensual</span>
            </template>
            <template v-slot:item.tiempo_disponible="{ item }">
                <span>{{ calcularTiempo(item) }}</span>
            </template>
            <!-- <template v-slot:item.last_active_state.estado.nombre="{ item }">
                <template v-if="item.last_active_state != null">
                    <span v-if="item.last_active_state.estado != null">{{
                        item.last_active_state.estado.nombre
                    }}</span>
                    <span v-else>{{ item.last_active_state.descripcion }}</span>
                </template>
            </template> -->
            <template v-slot:item.ultimo_seguimiento="{ item }">
                <span>{{ item.ultimo_seguimiento | format_date }}</span>
            </template>
            <template v-slot:item.fecha_firma="{ item }">
                <span>{{ item.fecha_firma | format_date }}</span>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon
                    @click.stop="openModal(item)"
                    small
                    class="mr-2"
                    color="red"
                    style="font-size: 25px"
                    title="BORRAR"
                    >mdi-trash-can</v-icon
                >
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas eliminar?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            selectedItem = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="deleteProyecto()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import debounce from "lodash/debounce";
import FilterComponentVue from "../../../components/general/FilterComponent.vue";
import { servicios_mixin } from "../../../global_mixins/servicios_mixin";

export default {
    mixins: [servicios_mixin],

    components: {
        FilterComponentVue,
    },
    data() {
        return {
            filtros_prueba: {
                search: "",
                semana: {
                    tipo: null,
                    value: null,
                },
                servicio: {
                    tipo: null,
                    value: null,
                },
                estado: {
                    tipo: null,
                    value: null,
                },
            },
            filter_headers: [
                {
                    title: "Tipo de Proyecto",
                    type: "select",
                    active: false,
                    model: "semana",
                    item_text: "nombre",
                    item_value: "id",
                    items: [
                        {
                            id: 0,
                            nombre: "Unico",
                        },
                        {
                            id: 1,
                            nombre: "Semanal",
                        },
                        {
                            id: 3,
                            nombre: "Mensual",
                        },
                    ],
                },
                {
                    title: "Producto Contratado",
                    type: "select",
                    active: false,
                    model: "servicio",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Estado Proyecto",
                    type: "select",
                    active: false,
                    model: "estado",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
            ],
            search: "",
            loading: false,
            headers: [
                {
                    text: "Proyecto",
                    value: "nombre",
                    sortable: false,
                },

                {
                    text: "Cliente",
                    value: "usuario.nombre",
                    sortable: false,
                },
                {
                    text: "Estado",
                    value: "last_active_state.estado.nombre",
                    sortable: false,
                },
                {
                    text: "Siguiente seguimiento",
                    value: "ultimo_seguimiento",
                    sortable: false,
                },
                {
                    text: "Minutos disponibles",
                    value: "tiempo_disponible",
                    sortable: false,
                },
                {
                    text: "Producto",
                    value: "servicio.nombre",
                    sortable: true,
                },
                {
                    text: "Fecha firma",
                    value: "fecha_firma",
                    sortable: true,
                },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false,
                },
            ],
            proyectos: [],
            totalItems: 67,
            options: {
                page: 1,
                itemsPerPage: 15,
                sortBy: [],
                sortDesc: [],
            },
            selectedItem: 0,
            dialog: false,
            toggle_exclusive: 0,

            // Auxiliares
            sortBy: "",
            sortDesc: true,
        };
    },
    created() {
        this.getEstados();
        this.getProyectos();
    },
    mounted() {
        this.filter_headers[1].items = this.servicios;
    },
    watch: {
        servicios(val) {
            this.filter_headers[1].items = val;
        },
        search: debounce(function (n) {
            this.getProyectos();
        }, 500),
        filtros_prueba: {
            deep: true,
            handler: debounce(function (n) {
                this.getProyectos();
            }, 500),
        },
        toggle_exclusive(n) {
            this.getProyectos();
        },
    },
    methods: {
        // Listado de proyecto
        getProyectos() {
            this.loading = true;
            axios
                .get(`api/get-cliente-proyectos`, {
                    params: {
                        itemsPerPage: this.options.itemsPerPage,
                        page: this.options.page,
                        sortBy: "ultimo_seguimiento",
                        gestion: true,
                        // sortDesc: this.options.sortDesc[0],
                        search: this.filtersParams.search,
                        semana: this.filtersParams.semana,
                        servicio: this.filtersParams.servicio,
                        estado: this.filtersParams.estado,
                        activo:
                            this.toggle_exclusive == 0
                                ? 1
                                : this.toggle_exclusive == 1
                                ? 0
                                : null,
                    },
                })
                .then(
                    (res) => {
                        this.proyectos = res.data.data;
                        this.totalItems = res.data.total;
                        this.loading = false;

                        // for (let i = 0; i < this.proyectos.length; i++) {
                        //     const element = this.proyectos[i];
                        //     element.created_at = new Date(
                        //         element.created_at
                        //     ).toLocaleDateString();
                        // }
                    },
                    (err) => {
                        this.$toast.error("Error consultando Usuario");
                    }
                );
        },
        onOptionsUpdate(newOptions) {
            this.options = newOptions;

            if (this.options.sortBy.length > 0) {
                this.sortBy = this.options.sortBy[0];
            } else {
                this.options.sortBy = [this.sortBy];
            }
            if (this.options.sortDesc.length > 0) {
                this.sortDesc = this.options.sortDesc[0];
            } else {
                this.options.sortDesc = [!this.sortDesc];
            }

            this.getProyectos();
        },
        exportExcel() {
            axios
                .get("api/export-cliente-proyectos", {
                    params: {
                        itemsPerPage: this.options.itemsPerPage,
                        page: this.options.page,
                        sortBy: "ultimo_seguimiento",
                        gestion: true,
                        // sortDesc: this.options.sortDesc[0],
                        search: this.filtersParams.search,
                        semana: this.filtersParams.semana,
                        servicio: this.filtersParams.servicio,
                        estado: this.filtersParams.estado,
                        activo:
                            this.toggle_exclusive == 0
                                ? 1
                                : this.toggle_exclusive == 1
                                ? 0
                                : null,
                    },
                    responseType: "blob",
                })
                .then((res) => {
                    // console.log("data", res.data);

                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "GestionProyectos.xlsx");
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$toast.error(err.response.data);
                });
        },

        // Eliminar proyecto
        openModal(item) {
            this.selectedItem = this.proyectos.indexOf(item);
            this.dialog = true;
        },
        deleteProyecto() {
            axios
                .get(
                    `api/delete-proyecto/${
                        this.proyectos[this.selectedItem].id
                    }`
                )
                .then(
                    (res) => {
                        this.$toast.sucs("Proyecto eliminado");
                        this.dialog = false;
                        this.getProyectos();
                    },
                    (err) => {
                        this.$toast.error("Error eliminando proyecto");
                    }
                );
            // this.getUsuarios();
        },

        // Metodo general
        getEstados() {
            axios.get("api/get-proyecto-estados").then((res) => {
                this.filter_headers[2].items = res.data;
            });
        },

        // Metodo auxiliar
        calcularTiempo(proyecto) {
            if (proyecto.semanal == 0) {
                return parseFloat(
                    proyecto.minutos_estimados - proyecto.tareas_sum_tiempo
                ).toFixed(0);
            }
            if (proyecto.semanal == 2) {
                return parseFloat(
                    proyecto.minutos_estimados -
                        proyecto.tareas_mensuales_sum_tiempo
                ).toFixed(0);
            }
            return 0;
        },

        // En desuso
        /*getAllClientes() {
                axios.get(`api/get-all-clientes`).then(
                    (res) => {
                        this.proyectos = res.data.users;
                        for (let i = 0; i < this.proyectos.length; i++) {
                            const element = this.proyectos[i];
                            element.created_at = new Date(
                                element.created_at
                            ).toLocaleDateString();
                        }
                    },
                    (err) => {
                        this.$toast.error("Error consultando Usuario");
                    }
                );
            },
            verProyectos() {
                axios
                    .get(
                        `api/delete-usuario/${this.proyectos[this.selectedItem].id}`
                    )
                    .then(
                        (res) => {
                            this.$toast.sucs("Usuario eliminado");
                            this.dialog = false;
                        },
                        (err) => {
                            this.$toast.error("Error eliminando Usuario");
                        }
                    );
                this.getUsuarios();
            },
            getInactiveClientes() {
                axios.get(`api/get-all-clientes-inactive-proyectos`).then(
                    (res) => {
                        this.proyectos = res.data.users;
                        for (let i = 0; i < this.proyectos.length; i++) {
                            const element = this.proyectos[i];
                            element.created_at = new Date(
                                element.created_at
                            ).toLocaleDateString();
                        }
                    },
                    (err) => {
                        this.$toast.error("Error consultando Usuario");
                    }
                );
            },*/
    },
    computed: {
        isloading: function () {
            return this.$store.getters.getloading;
        },
        filtersParams() {
            let params = {};

            if (this.filtros_prueba.search) {
                params.search = this.filtros_prueba.search;
            }

            this.filter_headers.forEach((head) => {
                if (this.filtros_prueba[head.model]) {
                    if (head.type == "date") {
                        if (this.filtros_prueba[head.model].start) {
                            params[`${head.model}_inicio`] =
                                this.filtros_prueba[head.model].start;
                        }
                        if (this.filtros_prueba[head.model].end) {
                            params[`${head.model}_fin`] =
                                this.filtros_prueba[head.model].end;
                        }
                    } else if (
                        this.filtros_prueba[head.model].value ||
                        this.filtros_prueba[head.model].nombre
                    ) {
                        params[head.model] =
                            this.filtros_prueba[head.model].value ||
                            this.filtros_prueba[head.model].nombre;
                    }
                }
            });

            return params;
        },
    },
};
</script>
