<template>
    <div>
        <div style="display: flex; flex-direction: row">
            <v-autocomplete
                @update:search-input="SearchProyecto"
                v-model="id_proyecto"
                :items="proyectos"
                item-text="nombre"
                item-value="id"
                :label="label"
                :outlined="outlined"
                :prepend-icon="prependIcon"
                :rules="rules"
            >
                <template v-slot:append-item>
                    <div v-intersect="endIntersect"></div>
                </template>
            </v-autocomplete>
        </div>
    </div>
</template>
<script>
import debounce from "lodash/debounce";

export default {
    props: [
        "value",
        "extra",
        "label",
        "item-text",
        "enabled",
        "outlined",
        "rol",
        "prepend-icon",
        "rules"
    ],
    data() {
        return {
            creado: null,
            rules: {
                required: value => !!value || 'Requerido.',
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    return pattern.test(value) || "E-mail Inválido.";
                },
                fiscal: (value) => {
                    const pattern = /^[A-Z]+$/i;
                    return pattern.test(value) || "E-mail Inválido.";
                },
            },
            id_proyecto: null,
            proyecto: {},
            dialog: false,
            users: [],
            update: false,
        };
    },
    watch: {
        value(val) {
            this.id_proyecto = val;
        },
        id_proyecto(val) {
            this.$emit("input", val);
        },
    },
    methods: {
        SearchProyecto: debounce(function (val) {
            this.$store.dispatch("searchProyecto", {
                rol: this.rol,
                search: val,
            });
        }, 500),
        endIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.$store.dispatch("getProyectosNext", { rol: this.rol });
            }
        },
    },
    mounted() {
        this.$store.dispatch("getProyectos", { rol: this.rol });
        if (this.value != null) {
            this.id_proyecto = this.value;
        }
        // this.getAgente();
    },
    computed: {
        agenteItems() {
            return this.users.map((user) => {
                return {
                    text: user.name + " " + user.apellidos,
                    value: user.id,
                };
            });
        },
        proyectos() {
            let proyectos_temp = this.$store.getters.getProyectos;
            if (this.extra?.nombre != null) {
                proyectos_temp.push(this.extra);
            }
            if (this.creado) {
                proyectos_temp.push(this.creado);
            }
            // console.log(proyectos_temp);
            return proyectos_temp;
        },
    },
};
</script>
