<template>
    <v-row>
        <v-col cols="8"><h4>Gasto Externo</h4></v-col>
        <v-col cols="4" align="end">
            <v-btn @click="openDialog(null)" small fab dense color="primary">
                <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-data-table :items="items" :headers="headers">
                <template v-slot:item.fecha="{ item }">
                    {{ item.fecha | format_date }}
                </template>
                <template v-slot:item.action="{ item }">
                    <v-icon
                        @click="openDialog(item)"
                        small
                        class="mr-2"
                        color="#1d2735"
                        style="font-size: 25px"
                        title="EDITAR"
                        >mdi-pencil-outline</v-icon
                    >

                    <v-icon
                        @click="openDeleteModal(item)"
                        small
                        class="mr-2"
                        color="red"
                        style="font-size: 25px"
                        title="BORRAR"
                        >mdi-trash-can</v-icon
                    >
                </template>
            </v-data-table>
        </v-col>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Guardar gasto
                </v-card-title>
                <v-card-text style="text-align: center">
                    <v-form ref="form">
                        <v-row dense>
                            <v-col cols="12" style="padding-top: 1.5rem">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Titulo"
                                    :rules="[rules.required]"
                                    v-model="selectedItem.titulo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" style="padding-top: 1.5rem">
                                <date-select
                                    outlined
                                    dense
                                    label="Fecha"
                                    :rules="[rules.required]"
                                    v-model="selectedItem.fecha"
                                ></date-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    :rules="[rules.required]"
                                    dense
                                    label="Importe"
                                    v-model="selectedItem.importe"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    outlined
                                    dense
                                    label="Descripción"
                                    v-model="selectedItem.descripcion"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            selectedItem = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="Add()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="delete_dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Eliminar gasto
                </v-card-title>
                <v-card-text style="text-align: center">
                    ¿Estás seguro de eliminarlo?
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            delete_dialog = false;
                            selectedIndex = {};
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="Eliminar()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: ["value"],
    data() {
        return {
            rules: {
                required: (value) => !!value || "Requerido",
            },
            items: [],
            selectedItem: {},
            selectedIndex: 0,
            dialog: false,
            delete_dialog: false,
            headers: [
                { text: "Titulo", value: "titulo" },
                { text: "Importe", value: "importe" },
                { text: "Fecha", value: "fecha" },
                { text: "Descripción", value: "descripcion" },
                { text: "Acciones", value: "action" },
            ],
        };
    },
    created() {
        if (this.value != null) {
            this.items = this.value;
        }
    },
    watch: {
        value(val) {
            if (val != null) {
                this.items = val;
            }
        },
        items(val) {
            console.log(val);
            this.$emit("input", val);
        },
    },
    methods: {
        Eliminar() {
            this.items.splice(this.selectedIndex, 1);
            this.delete_dialog = false;
        },
        openDeleteModal(item) {
            this.selectedIndex = this.items.findIndex((ele) => ele == item);
            this.delete_dialog = true;
        },
        openDialog(item) {
            this.dialog = true;
            this.$refs.form?.reset();

            if (item == null) {
                this.selectedItem = {};
                this.selectedIndex = null;
            } else {
                this.selectedIndex = this.items.findIndex((ele) => ele == item);
                this.selectedItem = JSON.parse(JSON.stringify(item));
            }
        },
        Add() {
            if (!this.$refs.form.validate()) return;
            if (this.selectedIndex != null) {
                this.items[this.selectedIndex] = JSON.parse(
                    JSON.stringify(this.selectedItem)
                );
                this.items.push({});
                this.items.pop();
            } else {
                this.items.push(JSON.parse(JSON.stringify(this.selectedItem)));
            }
            this.dialog = false;
        },
    },
};
</script>
