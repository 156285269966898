<template>
    <div>
        <v-data-table
            @click:row="
                (item) => {
                    $router.push(`/editar-ticket?id=${item.id}`);
                }
            "
            dense
            :loading="loading"
            :headers="headers"
            :items="items"
            item-key="id"
            :item-class="row_classes"
            class="elevation-1 pointer"
            :options.sync="options"
            @update:options="onOptionsUpdate"
            :server-items-length="totalItems"
        >
            <template v-if="actions" v-slot:item.action="{ item }">
                <v-icon
                    @click.stop="openModal(item.id)"
                    small
                    class="mr-2"
                    :color="
                        item.id_urgencia == 1 ||
                        item.fecha_finalizacion == fecha_hoy
                            ? 'white'
                            : 'red'
                    "
                    style="
                        font-size: 25px;
                        background-color: transparent !important;
                    "
                    title="BORRAR"
                >
                    mdi-trash-can
                </v-icon>
            </template>
        </v-data-table>

        <DeleteDialogVue
            v-model="dialog"
            :id="selectedId"
            @delete="deleteItem"
        />
    </div>
</template>
<script>
import DeleteDialogVue from "../general/DeleteDialog.vue";
import debounce from "lodash/debounce";

export default {
    props: ["id_proyecto", "filtros", "actions"],
    components: { DeleteDialogVue },
    data() {
        return {
            loading: false,
            dialog: false,
            headers: [
                { text: "Ticket", value: "id", sortable: false },
                { text: "Cliente", value: "cliente", sortable: false },

                {
                    text: "Proyecto",
                    value: "proyecto",
                    sortable: false,
                },
                {
                    text: "Titulo",
                    value: "titulo",
                    sortable: false,
                },
                {
                    text: "Departamento",
                    value: "departamento",
                    sortable: false,
                },
                { text: "Estado", value: "estado" },
                { text: "Responsable", value: "responsable", sortable: false },
                {
                    text: "Fecha de creación",
                    value: "created_at",
                },
                {
                    text: "Fecha de realización",
                    value: "fecha_realizacion",
                },
                {
                    text: "Fecha de finalización",
                    value: "fecha_finalizacion",
                },
                { text: "Urgencia", value: "urgencia", sortable: false },
            ],
            items: [],
            options: {
                page: 1,
                itemsPerPage: 15,
                sortBy: [],
                sortDesc: [],
            },
            selectedId: null,
            totalItems: 0,
            fecha_hoy: null,

            // Auxiliares
            sortBy: "",
            sortDesc: true,
            filtrosCloned: {},
        };
    },
    created() {
        // this.getItems();
        this.fecha_hoy = this.formatDate(new Date());
        if (this.actions) {
            this.headers.push({
                text: "Acciones",
                value: "action",
                sortable: false,
            });
        }
    },
    mounted() {
        this.loadFiltros();
    },
    methods: {
        // Listar tickets
        getItems() {
            this.loading = true;

            let params = {};
            if (this.filtros != null) {
                params = {
                    page: this.options.page,
                    rowsPerPage: this.options.itemsPerPage,
                    sortBy: this.options.sortBy[0],
                    sortDesc: this.options.sortDesc[0],
                    fecha_inicio: this.filtros.fecha?.start,
                    fecha_fin: this.filtros.fecha?.end,
                    fecha_fianlizacion_inicio:
                        this.filtros.fecha_fianlizacion?.start,
                    fecha_fianlizacion_fin:
                        this.filtros.fecha_fianlizacion?.end,
                    estado: this.filtros.estado?.value,
                    responsable: this.filtros.responsable?.value,
                    departamento: this.filtros.departamento?.value,
                    urgencia: this.filtros.urgencia?.value,
                    cliente: this.filtros.cliente?.value,
                    id_proyecto: this.filtros.proyecto?.value,
                    search: this.filtros.search,
                };
            } else {
                params = {
                    page: this.options.page,
                    rowsPerPage: this.options.itemsPerPage,
                    sortBy: this.options.sortBy[0],
                    sortDesc: this.options.sortDesc[0],
                    id_proyecto: this.id_proyecto,
                };
            }

            axios.get(`api/get-tickets`, { params: params }).then(
                (res) => {
                    let response = res.data;

                    this.items = response.data;
                    this.totalItems = response.total;
                    this.loading = false;
                    // this.fecha_hoy = new Date();
                },
                (err) => {
                    this.$toast.error("Error consultando Usuario");
                }
            );
        },
        onOptionsUpdate(newOptions) {
            this.options = newOptions;

            if (this.options.sortBy.length > 0) {
                this.sortBy = this.options.sortBy[0];
            } else {
                this.options.sortBy = [this.sortBy];
            }
            if (this.options.sortDesc.length > 0) {
                this.sortDesc = this.options.sortDesc[0];
            } else {
                this.options.sortDesc = [!this.sortDesc];
            }

            this.getItems();
        },

        // Eliminar ticket
        openModal(id) {
            this.selectedId = id;
            this.dialog = true;
        },
        deleteItem(id) {
            axios.get(`api/delete-ticket/${id}`).then(
                (response) => {
                    this.$toast.sucs("Ticket eliminado");
                    this.dialog = false;
                    this.getItems();
                },
                (err) => {
                    this.$toast.error("Error eliminando Ticket");
                }
            );
        },

        // Guardar filtros
        saveFiltros(bool, item) {
            const filtros_modulo = this.$store.getters.get_filtros.tickets;

            if (bool) {
                filtros_modulo.filtros = item;
            } else {
                (filtros_modulo.page = item.page),
                    (filtros_modulo.itemsPerPage = item.itemsPerPage);
            }

            this.$store.dispatch("setFiltros", {
                name: "tickets",
                filtros: filtros_modulo,
            });

            this.$emit("update:filtros", filtros_modulo); // Emitir un evento para actualizar el padre
        },
        loadFiltros() {
            const filtros_modulo = this.$store.getters.get_filtros.tickets;

            if (filtros_modulo != null) {
                this.options.page = filtros_modulo.page;
                this.options.itemsPerPage = filtros_modulo.itemsPerPage;

                if (filtros_modulo.filtros == null) {
                    this.filtros.responsable = {
                        nombre: localStorage.getItem("user_name"),
                        value: localStorage.getItem("user_id"),
                    };
                } else {
                    this.filtros = filtros_modulo.filtros;
                }
            }
        },

        // Metodos auxiliares
        formatDate(date) {
            const day = date.getDate().toString().padStart(2, "0"); // Obtener el día con ceros a la izquierda
            const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Obtener el mes con ceros a la izquierda
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
        row_classes(item) {
            if (
                item.id_urgencia == 1 ||
                item.fecha_finalizacion == this.fecha_hoy
            ) {
                return "red-row pointer";
            } else {
                return "pointer";
            }
        },
    },
    watch: {
        filtros: {
            deep: true,
            handler: debounce(function (n) {
                this.options.page = 1;
                this.getItems();
                this.saveFiltros(true, n);
            }, 500),
        },
        options: {
            deep: true,
            handler: function (val) {
                this.saveFiltros(false, val);
            },
        },
    },
    computed: {
        //
    },
};
</script>

<style>
.red-row {
    background-color: rgb(255, 202, 202);
}
</style>
