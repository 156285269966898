const modulo_estado = {
    strict: false,

    state: {
        is_loading: false,

        auth: false,

        user: {
            name: "...",
            role: 2,
            email: "",
            id: "",
        },
        tickets_pendientes: 0,
        redes_pendientes: 0,
        errors: {
            errors: {},
        },
    },

    getters: {
        getloading: (state) => state.is_loading,

        geterrors: (state) => state.errors,

        getuser: (state) => state.user,
        getTicketsPendietnes: (state) => state.tickets_pendientes,
        getRedesPendientes: (state) => state.redes_pendientes,

        getauth: (state) => state.auth,
    },

    mutations: {
        is_loading: (state, status) => (state.is_loading = status),

        is_errors: (state, errors) => (state.errors = errors),

        user: (state, user) => (state.user = user),
        tickets: (state, tickets) => (state.tickets_pendientes = tickets),
        redes: (state, redes) => (state.redes_pendientes = redes),
        auth: (state, status) => (state.auth = status),
    },

    actions: {
        isLoading: (context, status) => context.commit("is_loading", status),

        setErrors: (context, errors) => context.commit("is_errors", errors),

        setUser: (context, user) => context.commit("user", user),
        setTickets: (context, tickets) => context.commit("tickets", tickets),
        setRedes: (context, redes) => context.commit("redes", redes),

        setAuth: (context, status) => context.commit("auth", status),
    },
};

export default modulo_estado;
