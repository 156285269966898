<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-icon class="white--text" style="font-size: 45px"
                >mdi-ticket-account</v-icon
            >
            <pre><v-toolbar-title><h2>Tickets</h2></v-toolbar-title></pre>
        </v-toolbar>
        <loader v-if="isloading"></loader>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="'/'"
                    :loading="isloading"
                    :disabled="isloading"
                    color="blue"
                    class="mt-2 mx-3"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-arrow-left-bold-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Volver</span>
        </v-tooltip>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="{ path: `/guardar-ticket` }"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text">mdi-plus-box</v-icon>
                </v-btn>
            </template>
            <span>Nuevo Ticket</span>
        </v-tooltip>
        <v-row class="mx-2 my-2">
            <v-row>
                <v-col cols="6" justify="center">
                    <FilterComponentVue
                        :headers="filter_headers"
                        v-model="filtros_prueba"
                    ></FilterComponentVue>
                </v-col>
            </v-row>
        </v-row>

        <TicketDataTableVue
            ref="ticketDataTable"
            :filtros="filtros_prueba"
            :actions="edit"
            @update:filtros="updateFiltros"
        />

        <!-- Antes de usar el componente TicketDatatTable -->
        <!-- <v-data-table
            :item-class="row_classes"
            @click:row="
                (item) => {
                    $router.push(`/editar-ticket?id=${item.id}`);
                }
            "
            dense
            :page="page"
            :server-items-length="totalItems"
            :loading="loading"
            :headers="headers"
            :items="tickets"
            :search="search"
            :items-per-page="15"
            item-key="id"
            class="elevation-1"
            :sort-by="['nombre']"
            :sort-desc="[true]"
            @update:options="loadItems"
        >
            <template v-slot:item.action="{ item }">
                <v-icon
                    @click.stop="openModal(item)"
                    small
                    class="mr-2"
                    :color="
                        item.id_urgencia == 1 ||
                        item.fecha_finalizacion == fecha_hoy
                            ? 'white'
                            : 'red'
                    "
                    style="
                        font-size: 25px;
                        background-color: transparent !important;
                    "
                    title="BORRAR"
                >
                    mdi-trash-can
                </v-icon>
            </template>
        </v-data-table> -->
    </v-card>
</template>
<script>
import debounce from "lodash/debounce";
import FilterComponentVue from "../../../components/general/FilterComponent.vue";
import TicketDataTableVue from "../../../components/ticket/TicketDataTable.vue";

export default {
    mixins: [],

    components: {
        FilterComponentVue,
        TicketDataTableVue,
    },
    data() {
        return {
            filtros_prueba: {
                search: "",
                estado: {},
                fecha: {},
                fecha_fianlizacion: {},
                departamento: {},
                urgencia: {},
                cliente: {},
                responsable: {},
                proyecto: {},
            },
            filter_headers: [
                {
                    title: "Cliente",
                    type: "cliente",
                    active: false,
                    model: "cliente",
                    item_text: "nombre",
                    item_value: "id",
                },
                {
                    title: "Proyecto",
                    type: "select",
                    active: false,
                    model: "proyecto",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Fecha de creación",
                    type: "date",
                    active: true,
                    model: "fecha",
                },
                {
                    title: "Fecha de finalización",
                    type: "date",
                    active: true,
                    model: "fecha_fianlizacion",
                },
                {
                    title: "Estado",
                    type: "select",
                    active: false,
                    model: "estado",
                    item_text: "descripcion",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Departamento",
                    type: "select",
                    active: false,
                    model: "departamento",
                    item_text: "descripcion",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Urgencia",
                    type: "select",
                    active: false,
                    model: "urgencia",
                    item_text: "descripcion",
                    item_value: "id",
                    items: [],
                },
            ],

            toggle_exclusive: 0,

            userId: localStorage.getItem("user_id"),
        };
    },
    created() {
        if (this.userId == "164") {
            this.filtros_prueba.responsable = {
                nombre: localStorage.getItem("user_name"),
                value: localStorage.getItem("user_id"),
            };
        }

        this.getEstados();
        this.getDepartamentos();
        this.getUrgencia();
        this.getProyectos();

        if (this.edit) {
            this.filter_headers.push({
                title: "Responsable",
                type: "select",
                active: false,
                model: "responsable",
                item_text: "nombre",
                item_value: "id",
                items: [],
            });

            this.getResponsables();

            this.filtros_prueba.responsable = {
                nombre: localStorage.getItem("user_name"),
                value: localStorage.getItem("user_id"),
            };
        }
    },
    mounted() {
        this.loadFiltros();
    },
    watch: {
        /*filtros_prueba: {
            deep: true,
            handler: debounce(function (n) {
                // this.page = 1;
                // this.getAllTickets();
                this.saveFiltros();
            }, 500),
        },*/
    },
    methods: {
        // Metodos que lista las opciones de los filtros
        getProyectos() {
            axios.get("api/get-proyectos-activos").then((res) => {
                this.filter_headers[1].items = res.data;
            });
        },
        getEstados() {
            axios.get("api/get-estado-tickets").then((res) => {
                this.filter_headers[4].items = res.data.success;
            });
        },
        getDepartamentos() {
            axios.get("api/get-departamentos").then((res) => {
                this.filter_headers[5].items = res.data.success;
            });
        },
        getUrgencia() {
            axios.get("api/get-urgencia").then((res) => {
                this.filter_headers[6].items = res.data.success;
            });
        },
        getResponsables() {
            axios.post("api/get-usuarios-empleados-all").then((res) => {
                this.filter_headers[7].items = res.data.users;
            });
        },

        // Establecer los valores del filtro
        updateFiltros(newFiltros) {
            // this.filtros_prueba = newFiltros
            this.saveFiltros(newFiltros);
        },

        loadFiltros() {
            const filtros_modulo = this.$store.getters.get_filtros.tickets;
            if (filtros_modulo != null) {
                // this.page = filtros_modulo.page;
                // this.itemsPerPage = filtros_modulo.itemsPerPage;

                if (filtros_modulo.filtros == null) {
                    this.filtros_prueba.responsable = {
                        nombre: localStorage.getItem("user_name"),
                        value: localStorage.getItem("user_id"),
                    };
                } else {
                    this.filtros_prueba = filtros_modulo.filtros;
                }
                //this.getAllTickets();
            }
        },

        saveFiltros(newFiltros) {
            this.$store.dispatch("setFiltros", {
                name: "tickets",
                filtros: {
                    filtros: newFiltros.filtros,
                    page: newFiltros.page,
                    itemsPerPage: newFiltros.itemsPerPage,
                },
            });
        },
    },
    computed: {
        edit: function () {
            const role = this.role;
            return (
                role == 1 ||
                role == 5 ||
                role == 9 ||
                role == 7 ||
                role == 6 ||
                role == 12
            );
        },
        role: function () {
            return localStorage.getItem("role");
        },
        isloading: function () {
            return this.$store.getters.getloading;
        },
        user: function () {
            const user = this.$store.getters.getuser;
            return user;
        },
    },
};
</script>
