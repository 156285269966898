<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-icon class="white--text" style="font-size: 45px"
                >mdi-account-supervisor-circle</v-icon
            >
            <pre><v-toolbar-title><h2>Mensajes por Aprobar</h2></v-toolbar-title></pre>
        </v-toolbar>

        <v-row>
            <v-col cols="12" md="4">
                <v-text-field
                    prepend-icon="mdi-account-search"
                    v-model="search"
                    label="Buscar"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-data-table
            dense
            :headers="headers"
            :items="mensajes"
            :search="search"
            :items-per-page="15"
            item-key="id"
            class="elevation-1"
        >
            <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at | format_date_time }}</span>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon
                    @click="openFromDialog(item)"
                    small
                    class="mr-2"
                    color="#1d2735"
                    style="font-size: 25px"
                    title="Aprobar"
                    >mdi-check-circle</v-icon
                >
                <v-icon
                    @click="openEditDialog(item)"
                    small
                    class="mr-2"
                    color="#1d2735"
                    style="font-size: 25px"
                    title="EDITAR"
                    >mdi-pencil</v-icon
                >
                <v-icon
                    @click="openModal(item)"
                    small
                    class="mr-2"
                    color="red"
                    style="font-size: 25px"
                    title="BORRAR"
                    >mdi-trash-can</v-icon
                >
            </template>
        </v-data-table>

        <!-- Eliminar mensaje -->
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas eliminar?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            dialog = false;
                            dominio_selected = null;
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="deleteUser()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Editar mensaje -->
        <v-dialog v-model="edit_dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Editar mensaje
                </v-card-title>
                <v-card-text style="text-align: center">
                    <template v-if="mensaje_seleccionado != null">
                        <v-textarea
                            label="Mensaje"
                            v-model="mensaje_seleccionado.mensaje"
                        ></v-textarea>
                        <template v-if="mensaje_seleccionado.archivos != null">
                            <img v-if="getItemType('storage/chat/'+mensaje_seleccionado.archivos.url) == 'image'" 
                                :src="'storage/chat/'+mensaje_seleccionado.archivos.url" 
                                style="width: 100%" 
                                alt=""
                            >
                            <video v-else-if="getItemType('storage/chat/'+mensaje_seleccionado.archivos.url) == 'video'" 
                                :src="'storage/chat/'+mensaje_seleccionado.archivos.url"
                                style="width: 100%"
                                controls
                            ></video>
                            <a v-else 
                                class="pointer"
                                target="_blank"
                                :href="'storage/chat/'+mensaje_seleccionado.archivos.url"
                            >
                                <div class="d-flex align-center justify-center flex-column"
                                    style="height: 100%;"
                                >
                                    <div>
                                        <v-icon size="5rem">mdi-file-document</v-icon>
                                        <div>
                                            {{ mensaje_seleccionado.archivos.file_name }}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </template>
                    </template>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            edit_dialog = false;
                            dominio_selected = null;
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="editar()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Aprobar mensaje -->
        <v-dialog v-model="create_dialog" max-width="500px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text style="text-align: center">
                    <h2>¿Estás seguro que deseas Aprobarlo?</h2>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        large
                        @click="
                            create_dialog = false;
                            dominio_selected = null;
                        "
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="aprobar()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    data() {
        return {
            edit_dialog: false,
            create_dialog: false,
            search: "",
            headers: [
                {
                    text: "Enviado por",
                    value: "sender.nombre",
                    sortable: true,
                },
                {
                    text: "Recibido por",
                    value: "receiver.nombre",
                    sortable: true,
                },
                {
                    text: "Mensaje",
                    value: "mensaje",
                    sortable: true,
                },
                {
                    text: "Fecha",
                    value: "created_at",
                    sortable: true,
                },
                { text: "Acciones", value: "action", sortable: false },
            ],
            mensajes: [],
            mensaje_seleccionado: null,
            dialog: false,
            // create_dialog: false,
        };
    },
    created() {
        this.getMensajes();
    },
    methods: {
        // Listar mensajes
        getMensajes() {
            axios.get(`api/get-chats-pendientes`).then(
                (res) => {
                    this.mensajes = res.data;
                },
                (err) => {
                    this.$toast.error("Error consultando Mensajes");
                }
            );
        },

        // ACCIONES DE LA TABLA
        // Aprobar mensaje
        openFromDialog(item) {
            this.create_dialog = true;
            this.mensaje_seleccionado = item;
        },
        aprobar() {
            axios
                .post(`api/aprobar-mensaje/${this.mensaje_seleccionado.id}`)
                .then(
                    (res) => {
                        this.getMensajes();
                        this.create_dialog = false;
                    },
                    (err) => {
                        this.$toast.error("Error consultando Mensajes");
                    }
                );
        },

        // Editar mensaje
        openEditDialog(item) {
            this.edit_dialog = true;
            this.mensaje_seleccionado = JSON.parse(JSON.stringify(item));
        },
        editar() {
            axios
                .put(
                    `api/chats/mensaje/${this.mensaje_seleccionado.id}`,
                    this.mensaje_seleccionado
                )
                .then(
                    (res) => {
                        this.getMensajes();
                        this.edit_dialog = false;
                    },
                    (err) => {
                        this.$toast.error("Error editando Mensaje");
                    }
                );
        },
        
        // Eliminar emnsaje
        openModal(item) {
            this.mensaje_seleccionado = item;
            this.dialog = true;
        },
        deleteUser() {
            axios
                .delete(`api/chats/mensaje/${this.mensaje_seleccionado.id}`)
                .then(
                    (res) => {
                        this.$toast.sucs("Dominio/Hosting eliminado");
                        this.dialog = false;
                        this.getMensajes();
                    },
                    (err) => {
                        this.$toast.error("Error eliminando Mensaje ");
                    }
                );
        },

        // Obtener tipo de archivo
        getItemType(url) {
            if (/\.(jpg|jpeg|png|webp|avif|gif)$/i.test(url)) {
                return "image";
            } else if (/\.(mp4|avi|mkv|flv|mov|wmv|webm)$/i.test(url)) {
                return "video";
            } else {
                return "doc";
            }
        },
    },
    computed: {
        isloading: function () {
            return this.$store.getters.getloading;
        },
    },
};
</script>
