<template>
    <v-dialog v-model="dialog" max-width="600px">
        <v-card>
            <v-card-title class="text-h5 dialog-title">
                Agregar tarea a seguimiento del proyecto
            </v-card-title>
            <v-card-text class="mt-4">
                <v-row>
                    <v-col cols="12">
                        <date-select-vue
                            v-model="seguimiento.fecha"
                            label="Fecha"
                            :outlined="true"
                        >
                        </date-select-vue>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            outlined
                            label="Horas"
                            v-model="seguimiento.hora"
                            :items="horas"
                            item-value="value"
                            item-text="text"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            label="Comentario"
                            v-model="seguimiento.comentario"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn @click="dialog = false" color="red" class="white--text">Cancelar</v-btn>
                <v-btn @click="confirm()" color="success">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import dateSelectVue from './dateSelect.vue';

export default {
    props: [
        'value',
    ],
    components: { dateSelectVue },
    data(){
        return {
            dialog: false,
            seguimiento: {
                id_proyecto: null,
                fecha: '',
                hora: '',
                comentario: ''
            },
            horas: []
        }
    },
    created(){
        this.generateHoras();
    },
    methods: {
        generateHoras() {
            this.horas = [];
            for (let val = 9; val <= 19; val += 0.5) {
                let str = val.toString().split(".");
                let txt = "";
                if (str.length > 1) {
                    txt = str[0].toString().padStart(2, "0") + ":30";
                } else {
                    txt = val.toString().padStart(2, "0") + ":00";
                }
                this.horas.push({ text: txt, value: val });
            }
        },
        confirm(){
            this.$emit('confirm', this.seguimiento)
        }
    },
    watch: {
        value(val){
            this.dialog = val;
        },
        dialog(val){
            this.$emit('input', val)
        }
    },
    computed: {
        //
    }
}
</script>
<style>
.dialog-title{
    justify-content: center;
    background: #1d2735;
    color: white;
}
</style>