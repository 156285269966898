<template>
    <v-dialog v-model="dialog" max-width="650px">
        <v-card>
            <v-card-title class="text-h5 aviso" style="
                    justify-content: center;
                    background: #1d2735;
                    color: white;">
                Generar Tarea
            </v-card-title>
            <v-card-text style="text-align: center">
                <v-row justify="space-between" class="mt-2">
                    <v-col cols="12" md="4">
                        <date-select label="Fecha" v-model="tarea.fecha">
                        </date-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            type="number"
                            label="Tiempo (minutos)"
                            v-model="tarea.tiempo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-checkbox
                            label="Incidencia"
                            v-model="tarea.incidencia"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea label="Descripcion" v-model="tarea.descripcion">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn color="error" large @click="
                        dialog = false;
                        selectedItem = {};
                    ">Cancelar</v-btn>
                <v-btn color="success" large @click="saveTarea()">Confirmar</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: [
            "value",
            "id_proyecto",
            "fecha",
            "tiempo",
            "descripcion",
            "id_ticket",
        ],
        data() {
            return {
                dialog: false,
                tarea: {
                    id_proyecto: null,
                    descripcion: null,
                    tiempo: null,
                    fecha: null,
                    id_usuario: localStorage.getItem("user_id"),
                    incidencia: false,
                },
            };
        },

        created() {
            this.setData();
        },
        watch: {
            id_ticket: function(val) {
                this.setData();
            },
            id_proyecto: function(val) {
                this.setData();
            },
            fecha: function(val) {
                this.setData();
            },
            tiempo: function(val) {
                this.setData();
            },
            descripcion: function(val) {
                this.setData();
            },
            dialog(val) {
                this.$emit("input", val);
            },
            value(val) {
                this.dialog = val;
            },
        },
        methods: {
            setData() {
                const tarea = this.tarea;
                this.tarea = {
                    id_proyecto: this.id_proyecto ?? tarea.id_proyecto,
                    descripcion: this.descripcion ?? tarea.descripcion,
                    tiempo: this.tiempo ?? tarea.tiempo,
                    fecha: this.fecha ?? tarea.fecha,
                    id_usuario: tarea.id_usuario,
                    id_ticket: this.id_ticket,
                };
            },
            saveTarea() {
                axios.post(`api/save-tarea`, this.tarea).then(
                    (res) => {
                        this.$toast.success("Tarea creada correctamente");
                        this.dialog = false;
                    },
                    (err) => {
                        this.$toast.error("Error creando tarea");
                    }
                );
            },
        },
    };
</script>