<template>
    <div class="loader-overlay" v-if="loading">
        <v-progress-circular
            :size="100"
            :width="3"
            color="blue"
            indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
    props: ["loading"],
};
</script>

<style scoped>
.loader-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: #00000020;
}
</style>
